import { defineNuxtRouteMiddleware, navigateTo } from '#app'

export default defineNuxtRouteMiddleware((to, from) => {
  const auth = useAuth()

  if (!auth.loggedIn) {
    return navigateTo('/login')
  }

  const { meta = {} } = to
  const routeRoles = (meta.auth_roles || []).map((r: string) => r.toLowerCase())
  const routePermissions = (meta.auth_permissions || []).map((p: string) => p.toLowerCase())

  const userRoles = Array.isArray(auth.user?.roles) 
    ? auth.user.roles.map((r: string) => r.toLowerCase()) 
    : []
  const userPermissions = Array.isArray(auth.user?.permissions) 
    ? auth.user.permissions.map((p: string) => p.toLowerCase()) 
    : []

  const hasValidRole = routeRoles.length && userRoles.some(r => routeRoles.includes(r))
  const hasValidPermission = routePermissions.length && userPermissions.some(p => routePermissions.includes(p))
  const allow = hasValidRole || hasValidPermission || userRoles.includes('admin')

  if (!allow) {
    if (history.length > 2) {
      history.go(-1)
    } else {
      return navigateTo('/')
    }
  }
})